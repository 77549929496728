import "./App.css";
import Landing from "./components/Landing";
import Second from "./components/Second";
import Third from "./components/Third";

function App() {
  return (
    <>
      <div className="container">
        <Landing />
        <Second />
        <Third />
      </div>
    </>
  );
}

export default App;
