import React from "react";
import styles from "./Second.module.css";

const Second = () => {
  return (
    <div className={styles.top}>
      <div className={styles.top_content}>
        <div className={styles.txt}>
          <h2>
            <span>Exciting Announcement :</span> Era Swap Community Successfully
            Migrated <br /> to Proof of Gods NFT Collections
          </h2>
        </div>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </div>
      <hr className={styles.line} />

      <div className={styles.top_section}>
        <h3>
          We are thrilled to announce that the EraSwap Community has migrated to
          the Eraswap Proof-of-Gods NFT! To participate, simply log in to your
          wallet using MetaMask and select the Polygon network to mint your NFT.
          Visit
          <span>
            <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank">
              [OpenSea.io]
            </a>
            <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank">
              (https://opensea.io/collection/proof-of-gods-unleashed)
            </a>{" "}
          </span>
          to get started. <br />
          <br />
          All EraSwap balance holders on the ES blockchain network are
          pre-whitelisted and your balances have been securely transferred to
          OpenSea. List your EraSwap NFT .
          <span>
            This NFT replaced your old EraSwap network balance as of June 1,
            2024.
          </span>
          <br />
          <br />
          Special Offer: All Era Swap Network users has been credited additional
          NFT airdrops. This collection features unique art, created by God
          Himself, bridging spirituality and the digital age. The Proof-of-Gods
          collection offers a limited supply of 49,048 NFTs, each a unique piece
          of divine art. Don't miss this chance to discover God's presence,
          receive blessings, and own a blockchain-based, spiritually significant
          NFT. Join us in celebrating this fusion of faith and technology by
          Listing your NFT today at , will receive 50% to 100% more NFT airdrops
          on August 1, 2024. This collection features unique art, created by God
          Himself, bridging spirituality and the digital age. The Proof-of-Gods
          collection offers a limited supply of 49,048 NFTs, each a unique piece
          of divine art. Don't miss this chance to discover God's presence,
          receive blessings, and own a blockchain-based, spiritually significant
          NFT. Join us in celebrating this fusion of faith and technology by
          Listing your NFT today at
          {" "}
          <span>
            <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank">
              [OpenSea.io]
            </a>
            <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank">
              (https://opensea.io/collection/proof-of-gods-unleashed)
            </a>{" "}
          </span>
          <br /> <br />
          <span>
            Embrace this opportunity where spirituality meets the digital age!
          </span>
        </h3>
      </div>
      <div className={styles.btn_group}>
        <a href="https://www.proofofgods.com" target="_blank" className={styles.PROOF}>GO TO PROOF OF GODS</a>
        <a href="https://opensea.io/collection/proof-of-gods-unleashed" target="_blank" className={styles.NFT}>
          GO TO PROOF OF GOD NFT COLLECTION
        </a>
      </div>
    </div>
  );
};

export default Second;
